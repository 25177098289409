.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: .4s;
}

.btn--purple {
  height: 68px;
  padding-left: 44px;
  padding-right: 44px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #fff;
  background-color: #6A65A4;

  &:hover {
    background-color: #C5C6E5;
    color: #6A65A4;
  }
}