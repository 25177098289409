.main-page {
  overflow: hidden;
}

.main {
  position: relative;
}

.main__slider .swiper-slide {
  height: 760px;
  padding-top: 105px;
  position: relative;

  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 550px;
    background: linear-gradient(180deg, rgba(7, 0, 67, 0.7) 0%, rgba(4, 0, 36, 0) 100%);
  }
}

.main__bg-logo {
  position: absolute;
  width: 400px;
  left: 50%;
  top: 112px;
  transform: translateX(-50%);
  margin-left: 440px;
  z-index: 50;
  opacity: .3;
}

.main__logo {
  margin-bottom: 85px;
  text-align: center;

  img {
    max-width: 350px;
  }
}

.main__title {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;

  span {
    display: flex;
    justify-content: center;
    color: #C5C6E5;
    align-items: center;

    img {
      margin-right: 36px;
    }
  }
}

.main__description {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  max-width: 706px;
  margin: 0 auto;
  text-transform: none;
}

.main__bottom {
  position: absolute;
  left: 0;
  bottom: 23px;



  .container {
    display: flex;
    align-items: center;

    &:before {
      content: "";
      position: relative;
      width: 165px;
      display: block;
      height: 1px;
      background-color: #fff;
      margin-right: 50px;
    }
  }

  .socials {
    margin-bottom: 0px;
    margin-right: 60px;
  }
}

.slider-nav.slider-nav--main {
  padding-top: 0px;

  svg {
    fill: #fff;
    opacity: 1;
  }

  .slider-numbers {
    color: #fff;
  }

  .next,
  .prev {

    &:hover {
      svg {
        opacity: .5;
        fill: #fff;
      }
    }
  }
}



.header--main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 50;
  background: transparent;

  .header__wrap {
    border-bottom: 1px solid rgba(217, 217, 217, 0.2);
    height: auto;
  }

  .search__input {
    background: none;
  }
}

.inner {
  padding-top: 30px;
  background: url(../img/inner-bg.svg) no-repeat right -60px top -299px / auto;
}

.scrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 68px;
}

.scrumbs__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-right: 18px;
  margin-right: 17px;
  border-right: 1px solid #6A65A4;
  color: #6A65A4;
  transition: color .4s;

  &:last-child {
    margin-right: 0px;
    padding-right: 0px;
    border: none;
    color: #D9D9D9;

    &:hover {
      color: #6A65A4;
    }
  }
}

.about__list {
  display: flex;
  justify-content: space-between;

}


.ico-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  img {
    width: 54px;
    margin-right: 41px;
  }
}

.ico-text {
  width: calc(100% - 95px);
  font-size: 16px;
  line-height: 25px;
}

.about__text {
  width: calc(100% - 540px);
}

.about__pic {
  width: 468px;
  height: 609px;
}

.about__list {
  margin-bottom: 50px;
}


.about__video {
  display: flex;
}

.about__video-play {
  width: 547px;
  position: relative;



  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &:hover .after svg {
      fill: #6A65A4;
    }

    .after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 93px;
      height: 93px;

      svg {
        filL: #fff;
        transition: .4s;
      }
    }
  }

  img {
    width: 100%;
  }
}

.about__video-description {
  width: calc(100% - 547px);
  background-color: #18143E;
  display: flex;
  align-items: center;
  padding: 50px 65px;
  color: #fff;
  position: relative;

  p {
    max-width: 635px;
    line-height: 25px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 3000px;
    background-color: #18143E;
  }
}

.about__video {
  margin-bottom: 84px;
}

.title-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;

  .ico-text {
    max-width: 550px;
  }
}

.title-text {
  margin-bottom: 35px;
}

.activities {
  display: flex;
  margin-right: -35px;
  flex-wrap: wrap;
}

.activities__item {
  width: calc(33.33% - 35px);
  margin-right: 35px;
  margin-bottom: 80px;

  .text p {
    color: #3A3A3A;
  }
}

.activities__pic {
  height: 545px;
  margin-bottom: 30px;
}

.activities__title {
  color: #6A65A4;
  margin-bottom: 30px;

  span {
    display: block;
  }
}

.activities__bottom {
  display: flex;
  justify-content: space-between;
  margin-right: -35px;

  .activities__item {
    margin-bottom: 122px;
  }
}

.activities__additional-block {
  width: calc(66.66% - 35px);
  margin-right: 35px;
  padding-left: 50px;
}

.activities__description {
  padding: 45px;
  border-left: 1px solid #D9D9D9;
  border-top: 1px solid #D9D9D9;
  display: flex;
  align-items: flex-start;
  width: calc(100% + 20px);

  img {
    width: 38px;
    margin-right: 20px;
  }

  .text {
    width: calc(100% - 58px);
  }
}

.form {
  background-color: #18143E;
  padding: 77px 0 60px 100px;
  position: relative;

  .h4 {
    color: #fff;
    margin-bottom: 30px;
  }
}

.form__wrap {
  max-width: 673px;
}

.input {
  display: block;
  margin-bottom: 30px;
  position: relative;

  input {
    padding-left: 15px;
    height: 50px;
    border-bottom: 1px solid #fff;
    color: #fff;
    width: 100%;

    &::placeholder {
      color: #fff;
    }

    &:focus {
      border-color: #6A65A4;
    }

    &.-is-focus {
      border-color: #6A65A4;
    }
  }
}

.form__flex {
  display: flex;
  justify-content: space-between;

  .input {
    width: calc(50% - 10px);
  }
}


.form__submit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form__submit-description {
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  margin-right: 23px;
}

.activities-form {
  width: 3000px;

  &:before {
    content: "";
    position: absolute;
    width: 738px;
    height: 100%;
    left: 600px;
    top: -2px;
    background: url(../img/form-bg.svg) no-repeat top left / 100% auto;
  }
}


.input__error {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
  line-height: 25px;
  margin-top: 3px;
  color: #fff;
  display: none;

  &.-is-active {
    display: block;
  }
}

.tender__description {
  max-width: 738px;
  margin-bottom: 33px;
}

.manual {
  padding: 66px 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D9D9D9;
}

.manual__text {
  display: flex;
  align-items: center;
  max-width: 782px;

  img {
    width: 38px;
    margin-right: 24px;
  }

  p {
    font-size: 20px;
    line-height: 28px;

    span {
      font-weight: 700;
      color: #6A65A4;
    }
  }
}

.tenders-bottom {
  display: flex;
}

.tenders-presentation {
  height: 310px;
  padding-top: 60px;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
  text-align: center;
  width: 320px;

  &:hover {
    .tenders-presentation__ico {
      transform: translateY(-4px);
    }
  }
}

.tenders-contacts {
  width: calc(100% - 310px);
  padding: 97px 43px 0;
  height: 310px;
  border-left: 1px solid #D9D9D9;

  .h3 {
    margin-bottom: 30px;
    color: #6A65A4;
  }
}

.tenders-contacts__list {
  display: flex;
  justify-content: space-between;
}


.tenders-contacts__item {
  color: #3A3A3A;
  padding-left: 5px;
  padding-right: 5px;

  p {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
  }

  span {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
  }
}


.tenders-presentation__title {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #6A65A4;
  margin-bottom: 25px;
}

.tenders-presentation__ico {
  transition: .4s;
}

.contacts-block {
  border: 1px solid #D9D9D9;
  margin-bottom: 68px;
  border-bottom: none;
}

.contacts {
  display: flex;
  justify-content: space-between;
  padding: 58px 39px;
  font-family: 'Montserrat';
}

.contact__name {
  font-size: 14px;
  line-height: 25px;
  font-weight: 300;
  margin-bottom: 6px;
}

.contact__city {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
}

.contacts__phone {
  font-size: 48px;
  line-height: 115%;
  margin-bottom: 10px;
}

.contacts__email {
  color: #6A65A4;
}

#map {
  height: 432px;
  width: 100%;
}

.form-contacts {
  background-image: url(../img/map-form.svg);
  background-repeat: no-repeat;
  background-size: 1571px auto;
  background-position: right -750px top -60px;
}



.project__tabs-items {
  display: flex;
  justify-content: center;
  border-left: 1px solid #D9D9D9;
  padding: 33px 0;
  font-family: 'Montserrat';
}

.project__tabs-item {
  margin-left: 25px;
  margin-right: 25px;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  color: #3A3A3A;
  cursor: pointer;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  &.-is-active {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #6A65A4;
  }
}


.project-slider {
  border-left: 1px solid #D9D9D9;
}


.project {
  display: flex;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.project__left {
  padding-top: 61px;
  padding-left: 33px;
  padding-bottom: 60px;
  padding-right: 25px;
  font-family: 'Montserrat';
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  width: 430px;

}

.project__name {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #6A65A4;
  margin-bottom: 37px;
}

.project__docs {
  display: flex;
}

.project__doc {
  width: 128px;
  margin-right: 33px;
}

.project_info {
  width: calc(100% - 161px);
}

.project__info-block {
  margin-bottom: 32px;
  font-size: 15px;
  line-height: 32px;
  color: #3A3A3A;

  &:last-child {
    margin-bottom: 0px;
  }

  p {
    font-weight: 700;
  }
}

.project__right {
  width: calc(100% - 430px);
  display: flex;
  background-color: #000;
}


.tab {
  display: none;

  &.-is-active {
    display: block;
  }
}

.project-slider {
  overflow: hidden;
}

.slider-nav {
  display: flex;
  align-items: center;
}

.slider-nav--projects {
  padding-top: 60px;
}

.prev,
.next {
  display: inline-flex;
  align-items: center;

  svg {
    fill: #6A65A4;
  }

  &:hover {
    svg {
      fill: #000
    }
  }
}

.tabs {
  margin-bottom: 120px;
}

.slider-numbers {
  font-weight: 400;
  font-size: 25.92px;
  line-height: 35px;
  color: #6A65A4;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-active {
  margin-right: 5px;
}

.main-activites__slider {
  overflow: hidden;

  .activities__item {
    width: 100%;
    margin-right: 0px;
  }
}

.main-activites {
  padding-top: 150px;
  padding-bottom: 60px;
  background: url(../img/inner-bg.svg) no-repeat left -220px top -299px / auto;
}

.main-activites__bottom {
  padding-top: 43px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 3000px;
    height: 1px;
    background-color: rgba(217, 217, 217, 0.6);
  }
}

.main-activites__link {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #6A65A4;

  &:hover {
    text-decoration: underline;
  }
}


.main-news__top {
  background: url(../img/news-title-bg.webp) no-repeat center / cover;
  padding: 110px 0;
}

.main-news__top {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

  h2.h2 {
    color: #fff;
    margin-bottom: 0px;
  }

  .ico-block {
    max-width: 420px;
    align-items: center;
    margin-bottom: 0px;
  }

  .ico-text {
    font-size: 16px;
  }
}

.news {
  display: flex;
  position: relative;
}

.news__left {
  width: 50%;
  background: url(../img/news-text.svg) no-repeat right center / 90px auto, url(../img/news-svg.svg) no-repeat right 210px top -20px / 738px auto;
  background-color: #C5C6E5;
  display: flex;
  justify-content: flex-end;
  padding-top: 145px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 132px;
}

.news__right {
  width: 50%;
  background-color: #18143E;
}

.news__left-wrap {
  max-width: 560px;
}

.news__top {
  display: flex;
  color: #6A65A4;
  font-family: 'Montserrat';
  margin-bottom: 40px;

  .news__date {
    margin-top: -15px;
  }
}

.news__main-date {
  margin-right: 31px;
}

.news__main-day {
  font-weight: 700;
  font-size: 79.5082px;
  line-height: 97px;
}

.news__date {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #6A65A4;
}

.news__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
}

.news__description {
  margin-bottom: 56px;
  max-width: 420px;
}

.news__item {
  display: flex;
  margin-bottom: 65px;

  &:hover {
    .news__small-title {
      text-decoration: underline;
      color: #fff;
    }
  }

  .news__date {
    width: 118px;
  }
}

.news__small-title {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #6A65A4;
  max-width: 240px;
  transition: .4s;
}

.news__all {
  .news__small-title {
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.news__right {
  display: flex;
  align-items: center;
}

.news__3d {
  min-width: 1086px;
  margin-left: 90px;
}

.map {
  padding-top: 60px;
  padding-bottom: 81px;
  background: url(../img/inner-bg.svg) no-repeat left -220px top -299px / auto;

  .ico-block {
    position: absolute;
    right: 20px;
    bottom: 0;
    align-items: center;
  }
}

.map-point {
  cursor: pointer;
  position: relative;
}

.map-point {
  &.active {
    z-index: 1000;

    foreignObject {
      display: inline-block;
      position: relative;
      z-index: 1;
    }

    rect {
      position: relative;
      z-index: 50;
    }
  }

  foreignObject {
    width: 100vw;
    max-width: 280px;
    height: 83px;
    display: none;
    cursor: default;
  }
}

.map-point__message {
  left: 100%;
  bottom: 100%;
  max-width: 264px;
  width: 100vw;
  padding: 15px 48px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #18143E;
  background-color: #fff;
}

.map__pic {
  margin-top: -150px;

  svg {
    width: 100%;
  }
}


.anchor-arrow {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: -5px;
  width: 31px;
  height: 31px;
  background-color: #6A65A4;
  transform: rotateZ(45deg) translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transform: rotateZ(-45deg);
    position: relative;
    z-index: 10;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 46px;
    height: 46px;
    transform: translate(-50%, -50%);
    background-color: rgba(106, 101, 164, 0.3);
    z-index: 1;
  }
}

.--mobile {
  display: none;
}

.activities__mobile-main {
  display: none;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #6A65A4;
  position: relative;

  &.-is-active:after {
    transform: translateY(-50%) rotateZ(180deg);
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotateZ(0);
    width: 15px;
    height: 8px;
    background: url(../img/accardeon.svg) no-repeat center / contain;
  }
}


.slider-nav--map {
  display: none;
}


@media(max-width: 1300px) {
  .activities-form {
    width: calc(100% + 20px);
    padding-right: 20px;
  }


  .news__left {
    padding-right: 20px;
  }
}

@media(max-width: 1200px) {
  .title-group {
    display: block;
  }

  .header__logo {
    max-width: 80px;
  }

  .ico-block {
    justify-content: flex-start;
  }

  .nav {
    display: none;
  }

  .header__burger {
    display: block;
  }

  .about__video-description p {
    font-size: 12px;
    line-height: 150%;
  }

  .tenders-contacts__item {
    span {
      max-width: 192px;
      display: block;
    }
  }
}

@media(max-width: 980px) {

  .project-slider {
    border: none;
  }

  .project {
    display: block;
    border: none;
    margin-bottom: 0px;
  }

  .project__right {
    height: calc(100vw / 3);
    width: 100%;
  }

  .project__tabs-items {
    border: none;
    padding-top: 11px;
    padding-bottom: 11px;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .project__tabs-item {
    font-size: 14px;
    line-height: 25px;
    display: inline-block;
    vertical-align: top;
    margin-right: 62px;
    margin-left: 0px;

    &.-is-active {
      font-size: 15px;
      line-height: 25px;
    }
  }


  .project__name {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .project__doc {
    width: 73px;
    margin-right: 20px;
  }

  .project_info {
    width: calc(100% - 93px);
  }

  .project__info-block {
    font-size: 14px;
    line-height: 23px;
  }

  .project__info-block {
    margin-bottom: 13px;
  }

  .project__info-block p {
    font-size: 14px;
    line-height: 23px;
  }

  .project__left {
    width: 100%;
    padding-left: 0px;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .tenders-contacts__list {
    display: block;
  }

  .tenders-contacts {
    height: auto;
    padding-top: 43px;
    padding-left: 20px;
  }

  .tenders-contacts__item {
    margin-bottom: 20px;
  }

  .tenders-contacts__item span {
    max-width: 100%;
  }

  .manual__text p {
    font-size: 16px;
    line-height: 28px;
    padding-right: 5px;
  }

  .ico-block {
    img {
      display: none;
    }
  }

  .title-group {
    margin-bottom: 0px;
  }

  .footer__item {
    padding-left: 5px;
    padding-right: 5px;
  }

  .about__pic.--desktop {
    display: none;
  }

  .about__pic.--mobile {
    display: block;
    width: 100%;
    overflow: hidden;
    max-height: 600px;
    margin-bottom: 40px;
    height: auto;

    img {
      width: 100%;
    }
  }

  .about__text {
    width: 100%;
    margin-bottom: 42px;
  }

  .scrumbs__link {
    font-size: 12px;
    line-height: 13px;
    padding-right: 18px;
    margin-right: 18px;
  }

  .scrumbs {
    margin-bottom: 33px;
  }

  .h1,
  .h2 {
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 23px;
  }

  .ico-block {

    margin-bottom: 40px;
    align-items: center;
  }

  .ico-text {
    font-size: 14px;
    line-height: 25px;
  }

  .text p,
  body {
    font-size: 14px;
    line-height: 28px;
  }

  .text p {
    margin-bottom: 30px;
  }

  .about__list {
    margin-bottom: 0px;
  }

  .about__video {
    display: block;
  }

  .about__video-play {
    height: 400px;
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .about__video-description {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .about__video {
    margin-bottom: 0px;
  }

  .about__video-description p {
    font-size: 14px;
    line-height: 1.8;
  }

  .inner {
    background: none;
  }

  .h3 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .activities {
    .activities__item {
      width: calc(50% - 35px);
    }
  }

  .activities__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .activities__pic {
    height: 356px;
  }

  .activities__additional-block {
    width: calc(100% + 20px);
    margin-left: -20px;
    padding-left: 0px;
  }

  .slider-nav {
    justify-content: space-between;
  }

  .tabs {
    margin-bottom: 33px;
  }

  .slider-nav {
    padding-top: 33px;
  }

  .news {
    display: block;
  }

  .news__left {
    width: 100%;
    justify-content: flex-start;
    padding: 63px 20px 39px;
    background: #C5C6E5 url(../img/news-text.svg) no-repeat right center/90px auto;
  }

  .news__right {
    width: 100%;
  }

  .main-activites__bottom .slider-nav {
    padding-top: 0px;
  }

  .news__right {
    height: 200px;
    overflow: hidden;
  }

  .news__3d {
    min-width: 400px;

    img {
      width: 100%;
    }

  }

  .map .ico-block {
    right: auto;
    bottom: auto;
    left: 20px;
    top: 80px;
    width: 100%;
  }
}


@media(max-width: 768px) {

  .map {
    padding-top: 46px;
    padding-bottom: 40px;
    background: none;
  }

  .map__pic {
    width: 150%;
    margin-top: -8vw;
    transition: transform .4s;

    &.-is-active {
      transform: translateX(-30%);
    }
  }

  .slider-nav--map {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 164px;
    margin: 0 auto;
  }

  .map__pic svg {
    height: auto;
  }

  .manual {
    padding-top: 20px;
    padding-bottom: 36px;
    padding-left: 0px;
    padding-right: 0px;
    border-left: none;
    border-right: none;
    display: block;

    .btn {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }
  }

  .manual__text {
    display: block;
    margin-bottom: 25px;

    img {
      float: right;
      margin-left: 5px;
      padding-bottom: 5px;
    }
  }

  .tenders-bottom {
    display: block;
  }

  .tenders-contacts {
    padding-left: 0px;
    border-left: none;
    width: 100%;
  }

  .tenders-presentation {
    height: auto;
    padding-top: 0px;
    border-left: none;
  }

  .footer__left {
    flex-wrap: wrap;
  }

  .footer__item {
    width: 50%;
    margin-bottom: 30px;
  }

  .footer__blocks {
    display: block;
  }

  .activities__description {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    margin-left: 20px;
    padding-top: 25px;
    padding-bottom: 25px;

    img {
      margin-right: 0px;
      float: right;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .text {
      width: 100%;
    }
  }

  .form {
    padding-left: 0px;
  }

  .contacts {
    padding-top: 24px;
    padding-bottom: 16px;
    padding-left: 0px;
    padding-right: 0px;
    display: block;
  }

  .contacts-block {
    border-left: 0px;
    border-right: 0px;
  }

  .contacts__left {
    margin-bottom: 30px;
  }

  .activities-form:before {
    left: 54%;
    transform: translateX(-50%);
    width: 320px;
  }

  .header {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .header__wrap {
    padding-left: 20px;
    padding-right: 20px;

    &.container {
      padding-left: 20px;
    }
  }

  .main__slider .swiper-slide {
    padding-top: 102px;
  }

  .main__logo {
    margin-bottom: 40px;

    img {
      max-width: 163px;
    }
  }

  .main__title {
    font-size: 23px;
    line-height: 28px;
  }

  .main__title span img {
    max-width: 30px;
    margin-right: 10px;
  }

  .main__description {
    max-width: 320px;
  }

  .main__bottom .container:before {
    display: none;
  }

  .main-news__top {
    padding-top: 49px;
    padding-bottom: 33px;

    h2.h2 {
      margin-bottom: 46px;
    }

    .container {
      display: block;
    }
  }


}

@media(max-width: 600px) {
  .news__main-day {
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 1px;
  }

  .news__date {
    font-size: 15px;
    line-height: 18px;
  }

  .form-contacts {
    position: relative;

    &:before {
      left: 54%;
      transform: translateX(-50%);
      width: 320px;
      content: "";
      position: absolute;
      height: 100%;
      top: -2px;
      background: url(../img/form-bg.svg) no-repeat top left/100% auto;
    }
  }

  .news__title {
    font-size: 14px;
    line-height: 17px;
  }

  .news__top .news__date {
    margin-top: 0px;
  }

  .news__top {
    align-items: center;
    margin-bottom: 14px;
  }

  .news__description {
    margin-bottom: 20px;
  }

  .news__item .news__date {
    width: 72px;
  }

  .news__3d {
    margin-left: 50px;
    max-width: 500px;
  }



  .news__small-title {
    padding-left: 5px;
    font-size: 14px;
    line-height: 17px;
  }

  .news__item {
    margin-bottom: 25px;
  }

  .main__bottom {
    width: 100%;
  }

  .main__description {
    padding-top: 58px;
  }

  .main__slider .swiper-slide {
    height: 560px;
  }

  .main__bottom .socials {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .main__bottom .slider-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .slider-numbers {
      display: none;
    }
  }

  .form-contacts {
    background-image: none;
  }

  .form__flex {
    display: block;

    .input {
      width: 100%;
    }
  }

  .form__submit {
    display: block;
  }

  .form__submit-description {
    margin-bottom: 22px;
    margin-right: 0px;
  }

  .form__submit {
    width: 100%;
  }

  .activities .activities__item {
    width: 100%;
    margin-bottom: 0px;
  }

  .activities__title {
    display: none;
  }

  .activities__mobile-main {
    display: block;
    padding-right: 35px;

  }

  .activities__group {
    padding-bottom: 30px;
    display: none;

    &.-is-active {
      display: block;
    }
  }

  .main-activites__slider {
    .activities__mobile-main {
      display: none;
    }

    .activities__group {
      display: block;
    }

    .activities__title {
      display: block;
    }

    .activities__pic {
      height: 280px;
    }

    .activities__item {
      margin-bottom: 0px;
    }


  }

  .main-activites__bottom {
    display: block;
    text-align: center;
  }

  .main-activites__bottom .slider-nav {
    padding-top: 0px;
    max-width: 164px;
    margin: 0 auto 30px;
  }

  .activities__item .text p {
    font-size: 14px;
    line-height: 20px;
  }

  .contacts__phone {
    font-size: 33px;
  }
}

@media(max-width: 500px) {
  .contacts__phone {
    text-align: center;
  }

  .contacts__email {
    width: 100%;
    text-align: center;
    max-width: 160px;
    margin: 0 auto;
    display: block;
  }

  .footer__left {
    display: block;
  }

  .footer__item {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer__logo {
    text-align: center;
    margin-bottom: 30px;
  }

  .footer {
    background-position: center bottom;
    padding-bottom: 105px;
  }

  .footer__banner {
    height: 210px;
  }

  .footer__wrap {
    padding-top: 66px;
  }

  .footer__title {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 5px;
  }

  .footer__text {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  .footer__bottom .footer__left {
    display: none;
  }

  .footer__bottom {
    padding: 0;
    justify-content: center;
    border: none;
  }

  .about__video-description {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header__lang:not(.-is-active) {
    display: none;
  }

  .header__wrap {
    padding-right: 0px;
  }

  .search {
    margin-right: 30px;
  }

  .-is-active .search__input {
    width: 158px;
  }

  .header__lang {
    font-size: 12px;
  }

  .main__description {
    font-size: 15px;
    line-height: 20px;
  }
}


@media(max-width: 400px) {
  .main__title {
    max-width: 200px;
    text-align: left;
    margin: 0 auto;
  }
}