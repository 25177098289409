* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    background-color: #F4F4F4;
    color: var(--black);
}


a {
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
}

button,
input {
    background-color: transparent;
    border: none;
}

button {
    cursor: pointer;
}

input:focus {
    outline: none;

    &::placeholder {
        opacity: 0;
    }
}

input::placeholder {
    transition: .4s;
}

section {
    position: relative;
}

.content {
    overflow: hidden;
}

.container {
    margin: 0 auto;
    position: relative;
    max-width: 1320px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 10;
}

.link {
    border-bottom: 1px solid #6A65A4;
    transition: .4s;

    &:hover {
        border-color: transparent;
    }
}

.inner {
    overflow: hidden;
}

.h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    margin-bottom: 44px;
    text-transform: uppercase;
    color: #040024;
}

.h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    margin-bottom: 44px;
    text-transform: uppercase;
    color: #040024;
}


.h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 32px;
}

.h4 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
}


.title--center {
    text-align: center;
}

.text {
    p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}