.footer {
  color: #fff;
  font-family: 'Montserrat';
  background: #040024 url(../img/footer-svg.svg) no-repeat -7% bottom/438px auto;
}

.footer__blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__left {
  display: flex;
  max-width: 850px;
  width: 100%;
  justify-content: space-between;
}

.footer__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #6A65A4;
  margin-bottom: 33px;
}

.footer__wrap {
  padding-top: 55px;
  padding-bottom: 60px;
  position: relative;
}

.footer__banner {
  height: 230px;
}

.footer__top {
  padding-bottom: 65px;
}

.footer__text {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 36px;
  max-width: 270px;

  &:last-child {
    margin-bottom: 0px;
  }
}


.footer__phones {
  .footer__text {
    margin-bottom: 0px;
  }
}

.socials {
  display: flex;
  margin-bottom: 40px;
}

.footer__logo {
  img {
    max-width: 200px;
  }
}

.socials__link {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid #fff;
  transition: .4s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  transition: .4s;

  &:hover {
    border-color: #6A65A4;
  }

  &:last-child {
    margin-right: 0px;
  }
}

.socials__link--tm {
  img {
    transform: translateX(-2px);
  }
}

.socials__link--vk {
  img {
    transform: translateY(1px);
  }
}

.footer__eamail {
  .footer__title {
    margin-bottom: 3px;
  }
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 37px;
  border-top: 1px solid rgba(217, 217, 217, 0.2);
  ;
}

.copiright,
.policy {
  font-size: 12px;
  line-height: 15px;
}

.policy {
  white-space: nowrap;
}