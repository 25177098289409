.header {
  background: #4A63BF linear-gradient(180deg, rgba(7, 0, 67, 0.7) 0%, rgba(4, 0, 36, 0) 813.24%);
  color: #fff;
  font-family: 'Montserrat';
}

.header__logo {
  max-width: 220px;
}

.header__wrap {
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.haeder__left {
  display: flex;
  align-items: center;
}

.header__burger {
  position: relative;
  height: 9px;
  width: 21px;
  margin-right: 32px;
  display: none;

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    height: 1px;
  }

  &:after {
    top: 0;
    width: 100%;
    background-color: #FFFFFF;
  }

  &:before {
    bottom: 0;
    background-color: #C5C6E5;
    transition: .4s;
    width: 14px;
  }

  &:hover {
    &:before {
      background-color: #fff;
      width: 100%;
    }
  }
}


.header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.nav__link {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-right: 35px;
  transition: .4s;

  &:last-child {
    margin-right: 0px;
  }

  &.active {
    text-decoration: underline;
    color: #6A65A4;
  }

  &:hover {
    text-decoration: underline;
    color: #6A65A4;
  }
}


.search {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 40px;
  z-index: 20;

  input {

    height: 38px;
    border-bottom: 1px solid #fff;
    padding-left: 13px;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #fff;
    transition: .4s;
    width: 100%;

    &::placeholder {
      color: #fff;
    }

    &:focus {
      border-color: #6A65A4;
    }
  }
}

.search__input {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  overflow: hidden;
  height: 68px;
  background: #4A63BF linear-gradient(180deg, rgba(7, 0, 67, 0.7) 0%, rgba(4, 0, 36, 0) 813.24%);
  transition: .4s;

  .-is-active & {
    width: 310px;
  }
}

.search__ico {
  position: relative;
  z-index: 20;
  cursor: pointer;
  transition: .4s;

  svg {
    fill: transparent;
    stroke: #6A65A4;
  }

  .-is-active & {
    transform: scale(.8) translateX(-15px);

    svg {
      stroke: #fff;
    }
  }

  .-is-active &.-is-active {
    svg {
      stroke: #6A65A4;
    }
  }
}

.header__search-trigger {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}


.haeder__phone {
  margin-right: 27px;

  &:hover {
    svg {
      fill: #6A65A4;
      stroke: #6A65A4;
    }
  }

  svg {
    fill: transparent;
    transition: .4s;
    stroke: #fff;
  }
}

.header__langs {
  display: flex;
}

.header__lang {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6A65A4;
  width: 58px;
  height: 68px;
  transition: .4s;

  &.-is-active {
    background-color: rgba(106, 101, 164, 0.5);
  }

  &:hover {
    background-color: rgba(106, 101, 164, 0.5);
    ;
  }
}

.mobile-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #6A65A4;
  padding: 80px 20px 40px;
  z-index: 999;
  display: none;

  &.-is-active {
    display: block;
  }

  .nav__link {
    display: block;
    font-size: 20px;
    line-height: 55px;
    color: #fff;

    &:hover {
      text-decoration: none;
      opacity: .6;
    }
  }
}

.mobile-nav__close {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.2);

}